import './bootstrap';

import * as jSuites from 'jsuites';
window.jSuites = jSuites;

import * as FilePond from 'filepond';
window.FilePond = FilePond;

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
FilePond.registerPlugin(FilePondPluginFileValidateType);

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
FilePond.registerPlugin(FilePondPluginFileValidateSize);
